import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import {
  matterDetailBundlesRequest,
  matterDetailDocumentsRequest,
  matterDetailsRequest,
} from '../../../api/matters';

import { useDocumentPermissions } from '../../../hooks/usePermissions';
import NotificationToast from '../../shared/toast/NotificationToast';
import MatterDetailDocumentFraudTab from './MatterDetailDocumentFraudTab';
import MatterDetailDocumentBundleTab from './MatterDetailDocumentBundleTab';
import MatterDetailDocumentTab from './MatterDetailDocumentTab';

interface MatterDetailDocumentsProps {
  control: any;
  setValue: any;
  matterId: any;
  matterDetails: any;
  setMatterDetails: any;
}

interface BundleOptionType {
  inputValue?: string;
  name: string;
  id: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      sx={{ display: value !== index ? 'none' : 'block' }}
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const MatterDetailDocuments = ({
  control,
  setValue,
  matterId,
  matterDetails,
  setMatterDetails,
}: MatterDetailDocumentsProps) => {
  const documentPerms = useDocumentPermissions();
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingBundle, setLoadingBundles] = useState(true);
  const [matterDetailDocs, setMatterDetailsDocs] = useState<any>({
    documents: [],
    page: 1,
    total_pages: 0,
    total_items: 0,
    has_next: false,
    has_previous: false,
  });
  const [matterDetailFolders, setMatterDetailsFolders] = useState<any>({
    folders: [],
    page: 1,
    total_pages: 0,
    total_items: 0,
    has_next: false,
    has_previous: false,
  });
  const [matterDetailBundles, setMatterDetailsbundles] = useState<any>();
  const [bundlesOptions, setBundlesOptions] =
    useState<readonly BundleOptionType[]>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [toggledIntegrations, setToggledIntegrations] = useState([]);
  const [foldersLoading, setFoldersLoading] = useState(true);

  const handleGetMatterDetailDocuments = async (page = 1, pageSize = 5) => {
    try {
      if (matterId && toggledIntegrations.length > 0) {
        setLoading(true);
        const response = await matterDetailDocumentsRequest(
          matterId,
          inputRef.current?.value || '',
          page,
          pageSize,
          'documents',
          toggledIntegrations as any
        );
        setMatterDetailsDocs(response);
        setLoading(false);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handleGetMatterDetailFolders = async (page = 1) => {
    try {
      if (matterId && toggledIntegrations.length > 0) {
        setFoldersLoading(true);
        const response = await matterDetailDocumentsRequest(
          matterId,
          inputRef.current?.value || '',
          page,
          1000,
          'folders'
        );

        setMatterDetailsFolders(response);
        setFoldersLoading(false);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      setFoldersLoading(false);
    }
  };

  const handleGetMatterDetailBundles = async () => {
    try {
      if (matterId) {
        setLoadingBundles(true);
        const response = await matterDetailBundlesRequest(
          matterId,
          inputRef.current?.value || ''
        );

        setMatterDetailsbundles(response);

        const bundlesOptionType: BundleOptionType[] = response.bundles.map(
          (bundle: any) => ({
            id: bundle.id,
            name: bundle.name,
          })
        );

        setBundlesOptions(bundlesOptionType);
        setLoadingBundles(false);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
      setLoadingBundles(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setTabValue(newValue);

    switch (newValue) {
      case 0:
        setLoading(true);
        setFoldersLoading(true);
        break;
      case 1:
        handleGetMatterDetailBundles();
        break;

      default:
        break;
    }
  };

  const handleListSearchbarInputChange = () => {
    switch (tabValue) {
      case 0:
        handleGetMatterDetailDocuments();
        handleGetMatterDetailFolders();
        break;
      case 1:
        handleGetMatterDetailBundles();
        break;
      default:
        break;
    }
  };

  const refreshTables = () => {
    handleGetMatterDetailDocuments();
    handleGetMatterDetailFolders();
    handleGetMatterDetailBundles();
  };

  useEffect(() => {
    refreshTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshTables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggledIntegrations]);

  const handleUpdateMatterDetails = async () => {
    try {
      if (matterId) {
        const response = await matterDetailsRequest(matterId);
        setMatterDetails(response);
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  return (
    <>
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            width: '100%',
            borderBottom: '1px solid #949CA9',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              '& > div': {
                '& > div': {
                  '& > button': {
                    display: 'flex',
                    width: '224px',
                    padding: '8px 24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    borderRadius: '24px 24px 0px 0px',
                    '&.Mui-selected': {
                      backgroundColor: '#F5FAFD',
                      color: '#0053FF',
                    },

                    color: '#69707B',
                    textTransform: 'none',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '22px',
                  },
                },
                '& > span.MuiTabs-indicator': {
                  backgroundColor: '#0053FF',
                },
              },
            }}
          >
            <Tab label="All Documents" {...a11yProps(0)} />
            <Tab label="Interview Bundles" {...a11yProps(1)} />
            {process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' && (
              <Tab label="Fraud Detection" {...a11yProps(2)} />
            )}
          </Tabs>
        </Box>
      </Box>

      <Box sx={{ width: '100%' }}>
        <CustomTabPanel value={tabValue} index={0}>
          <MatterDetailDocumentTab
            matterId={matterId}
            matterDetails={matterDetails}
            matterDetailDocs={matterDetailDocs}
            matterDetailFolders={matterDetailFolders}
            handleGetMatterDetailFolders={handleGetMatterDetailFolders}
            handleGetMatterDetailDocuments={handleGetMatterDetailDocuments}
            documentPerms={documentPerms}
            loadingDocs={loading}
            loadingFolders={foldersLoading}
            handleUpdateMatterDetails={handleUpdateMatterDetails}
            control={control}
            setValue={setValue}
            bundlesOptions={bundlesOptions}
            handleListSearchbarInputChange={handleListSearchbarInputChange}
            inputRef={inputRef}
            toggledIntegrations={toggledIntegrations}
            setToggledIntegrations={setToggledIntegrations}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <MatterDetailDocumentBundleTab
            matterId={matterId}
            matterDetails={matterDetails}
            matterDetailBundles={matterDetailBundles}
            handleGetMatterDetailBundles={handleGetMatterDetailBundles}
            documentPerms={documentPerms}
            loadingBundle={loadingBundle}
            handleListSearchbarInputChange={handleListSearchbarInputChange}
            inputRef={inputRef}
          />
        </CustomTabPanel>
        {process.env.REACT_APP_FRAUD_DETECTION_ENABLED === 'true' && (
          <CustomTabPanel value={tabValue} index={2}>
            <MatterDetailDocumentFraudTab
              matterId={matterId}
              matterDetails={matterDetails}
            />
          </CustomTabPanel>
        )}
      </Box>
    </>
  );
};

export default MatterDetailDocuments;
