import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { useState } from 'react';
import { karlaProRegularFontStyles } from '../../../styles/textStyles';
import LEIAADialog from '../../inputs/LEIAADialog';

interface FileActionsMenuProps {
  file: any;
  // action handlers
  addNoteHandler?: (id: string) => void;
  handleDownloadFile?: (file: any) => void;
  renameHandler?: (file: any) => void;
  fileDownloadLinkHandler?: (id: string) => void;
  informationSideBarHandler?: (file: any) => void;
  deleteHandler?: (file: any) => void;
  handleImanageDocumentsOperations?: (
    action: string,
    all_documents: boolean,
    targetResource: any | boolean,
    docs_to_upload?: any[],
    folder?: string
  ) => void;
  handleMicrosoftDocumentsOperations?: (
    action: string,
    targetResource: any
  ) => void;
  handleEdiscoveryDocumentsOperations?: (
    action: string,
    targetResource: any
  ) => void;
}

const FileActionsMenu = ({
  file,
  addNoteHandler,
  handleDownloadFile,
  renameHandler,
  fileDownloadLinkHandler,
  informationSideBarHandler,
  deleteHandler,
  handleImanageDocumentsOperations,
  handleMicrosoftDocumentsOperations,
  handleEdiscoveryDocumentsOperations,
}: FileActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openAlertOpenDialog, setOpenAlertOpenDialog] = useState(false);

  const closeDialogHandler = () => {
    setOpenAlertOpenDialog(false);
  };

  const deleteDialogHandler = () => {
    if (deleteHandler) {
      deleteHandler(file.id);
    }
    setOpenAlertOpenDialog(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleImportOrUpdate = () => {
    if (file.source === 'iManage' && handleImanageDocumentsOperations) {
      handleImanageDocumentsOperations('pull', false, file.id);
    }
    if (file.source === 'OneDrive' && handleMicrosoftDocumentsOperations) {
      handleMicrosoftDocumentsOperations('pull', file.id);
    }
    if (file.source === 'E-Discovery' && handleEdiscoveryDocumentsOperations) {
      handleEdiscoveryDocumentsOperations('pull', file.id);
    }
  };

  // Color is appear brighter than it should? OG is #0053FF
  const menuItemTextStyle = {
    color: '#1976d2',
    fontFamily: 'Karla, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.2px',
    textTransform: 'none',
    '& .MuiTypography-root': {
      color: '#1976d2',
      fontFamily: 'Karla, sans-serif',
      fontSize: '16px',
      fontWeight: 500,
    },
  };

  const menuIconStyle = {
    '& .material-icons-round': {
      color: '#1976d2',
      fontSize: '24px',
    },
  };

  const menuItemStyle = {
    '&:hover': {
      '& .material-icons-round': {
        color: '#0B25B0',
      },
      '& .MuiTypography-root': {
        color: '#0B25B0',
      },
    },
  };

  return (
    <>
      <LEIAADialog
        open={openAlertOpenDialog}
        onClose={() => setOpenAlertOpenDialog(false)}
        title="alert"
        description="You are about to archive a Document. Are you sure you want to archive it?"
        primaryButtonText="Yes, archive document"
        primaryButtonIcon="archive"
        onPrimaryButtonClick={deleteDialogHandler}
        secondaryButtonText="Cancel"
        secondaryButtonHasIcon={false}
        onSecondaryButtonClick={closeDialogHandler}
      />
      <Box>
        <Box
          sx={{
            color: '#69707B',
            ...karlaProRegularFontStyles,
            fontSize: '14px',
            lineHeight: '22px',
            '& > span': {
              fontSize: '20px',
            },
            '&:hover': {
              '& > *': {
                color: '#0B25B0',
              },
            },
          }}
          onClick={handleClick}
        >
          <span className="material-icons-round">more_vert</span>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 'auto',
              borderRadius: '5px',
              boxShadow: '0px 0px 10px rgba(21, 10, 84, 0.3)',
            },
          }}
        >
          <MenuList>
            {/* Integration Actions */}
            {/* TODO: import into folder */}
            {/* TODO: allow leiaa actions for stashed documents */}
            {file.status !== 'Stashed' && file.source !== 'Leiaa' && (
              <>
                {file.status === 'Unstashed' && (
                  <MenuItem sx={menuItemStyle} onClick={handleImportOrUpdate}>
                    <ListItemIcon sx={menuIconStyle}>
                      <span className="material-icons-round">download</span>
                    </ListItemIcon>
                    <ListItemText sx={menuItemTextStyle}>Import</ListItemText>
                  </MenuItem>
                )}
                {file.status === 'Stashed (Stale)' && (
                  <MenuItem sx={menuItemStyle} onClick={handleImportOrUpdate}>
                    <ListItemIcon sx={menuIconStyle}>
                      <span className="material-icons-round">sync</span>
                    </ListItemIcon>
                    <ListItemText sx={menuItemTextStyle}>Update</ListItemText>
                  </MenuItem>
                )}
                {file.status !== 'Stashing...' && <Divider />}
              </>
            )}
            {/* Document Actions */}
            {(file.source === 'Leiaa' ||
              file.status === 'Stashed' ||
              file.status === 'Stashed (Stale)') && (
              <>
                <MenuItem sx={{ ...menuItemStyle, pointerEvents: 'none' }}>
                  {/* TODO: remove this pointerEvents: 'none' after feature upgrade */}
                  <ListItemIcon sx={menuIconStyle}>
                    <span className="material-icons-round">upgrade</span>
                  </ListItemIcon>
                  <ListItemText sx={menuItemTextStyle}>Export</ListItemText>
                </MenuItem>

                <MenuItem
                  onClick={() => handleDownloadFile && handleDownloadFile(file)}
                  sx={menuItemStyle}
                >
                  <ListItemIcon sx={menuIconStyle}>
                    <span className="material-icons-round">cloud_download</span>
                  </ListItemIcon>
                  <ListItemText sx={menuItemTextStyle}>Download</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => renameHandler && renameHandler(file)}
                  sx={menuItemStyle}
                >
                  <ListItemIcon sx={menuIconStyle}>
                    <span className="material-icons-round">border_color</span>
                  </ListItemIcon>
                  <ListItemText sx={menuItemTextStyle}>Rename</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => addNoteHandler && addNoteHandler(file.id)}
                  sx={menuItemStyle}
                >
                  <ListItemIcon sx={menuIconStyle}>
                    <span className="material-icons-round">sticky_note_2</span>
                  </ListItemIcon>
                  <ListItemText sx={menuItemTextStyle}>Notes</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    fileDownloadLinkHandler && fileDownloadLinkHandler(file.id)
                  }
                  sx={menuItemStyle}
                >
                  <ListItemIcon sx={menuIconStyle}>
                    <span className="material-icons-round">file_present</span>
                  </ListItemIcon>
                  <ListItemText sx={menuItemTextStyle}>Share</ListItemText>
                </MenuItem>
              </>
            )}
            <MenuItem
              onClick={() => {
                if (informationSideBarHandler) {
                  informationSideBarHandler(file);
                }
                handleClose();
              }}
              sx={menuItemStyle}
            >
              <ListItemIcon sx={menuIconStyle}>
                <span className="material-icons-round">info</span>
              </ListItemIcon>
              <ListItemText sx={menuItemTextStyle}>Information</ListItemText>
            </MenuItem>
            {(file.source === 'Leiaa' ||
              file.status === 'Stashed' ||
              file.status === 'Stashed (Stale)') && (
              <>
                <Divider />
                <MenuItem
                  onClick={() => deleteHandler && setOpenAlertOpenDialog(true)}
                  sx={menuItemStyle}
                >
                  <ListItemIcon sx={menuIconStyle}>
                    <span className="material-icons-round">archive</span>
                  </ListItemIcon>
                  <ListItemText sx={menuItemTextStyle}>Archive</ListItemText>
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      </Box>
    </>
  );
};

export default FileActionsMenu;
