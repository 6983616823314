import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addToFraudDetectionDBRequest,
  batchDeleteMatterDocsRequest,
  createFolderRequest,
  createOrGetDownloadTokenRequest,
  createOrGetUploadTokenRequest,
  editDocumentRequest,
  folderDocsRequest,
  getDocumentURLRequest,
  matterDetailImanageDocOpsNewRequest,
  matterDetailIntegrationOperationsRequest,
  matterDetailRelativityDocOpsRequest,
  renameFolderRequest,
} from '../../../api/matters';
import { karlaProBoldFontStyles } from '../../../styles/textStyles';
import MatterNotesListModal from '../../matter-notes/MatterNotesListModal';
import CreationNotification from '../../shared/creation-notification/CreationNotification';
import DocumentAddToFolderDialog from '../../shared/docs-list-table/DocumentAddToFolderDialog';
import DocumentAddToInterviewDialog from '../../shared/docs-list-table/DocumentAddToInterviewDialog';
import DocumentDialog from '../../shared/docs-list-table/DocumentDialog';
import DocumentFolderPerms from '../../shared/docs-list-table/DocumentFolderPerms';
import DocumentsListTable from '../../shared/docs-list-table/DocumentsListTable';
import FolderFileInformationDrawer from '../../shared/document-detail/FolderFileInformationDrawer';
import ListButtons from '../../shared/list-buttons/ListButtons';
import { homePortalSearchInputStyles } from '../../shared/resource-page-header/styles';
import NotificationToast from '../../shared/toast/NotificationToast';
import { primaryButtonStyles, secondaryButtonStyles } from '../../styles';
import MatterDetailDocumentsFolders from './matter-documents/MatterDetailDocumentsFolders';
import MatterDetailDocumentsSources from './matter-documents/MatterDetailDocumentsSources';

interface MatterDetailDocumentTabProps {
  matterId: any;
  matterDetails: any;
  matterDetailDocs: any;
  matterDetailFolders: any;
  handleGetMatterDetailFolders: any;
  handleGetMatterDetailDocuments: any;
  documentPerms: any;
  loadingDocs: any;
  loadingFolders: any;
  handleUpdateMatterDetails: any;
  control: any;
  setValue: any;
  bundlesOptions: any;
  handleListSearchbarInputChange: any;
  inputRef: any;
  toggledIntegrations: any;
  setToggledIntegrations: any;
}

const MatterDetailDocumentTab = ({
  matterId,
  matterDetails,
  matterDetailDocs,
  matterDetailFolders,
  handleGetMatterDetailFolders,
  handleGetMatterDetailDocuments,
  documentPerms,
  loadingDocs,
  loadingFolders,
  handleUpdateMatterDetails,
  control,
  setValue,
  bundlesOptions,
  handleListSearchbarInputChange,
  inputRef,
  toggledIntegrations,
  setToggledIntegrations,
}: MatterDetailDocumentTabProps) => {
  const [checkedDocs, setCheckedDocs] = useState<any>([]);
  const [selectedDocs, setSelectedDocs] = useState<any | null>(null);
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<string | null>(null);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showArchiveNotification, setShowArchiveNotification] = useState(false);
  const [showRenameNotification, setShowRenameNotification] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openRenameDialog, setRenameDialogOpen] = useState(false);
  const [inputRenameValue, setInputRenameValue] = useState('');
  const [showAddToFraudDatabase, setShowAddToFraudDatabase] = useState(false);
  const [openAddToFolderDialog, setOpenAddToFolderDialog] = useState(false);
  const [openFolderPermsDialog, setOpenFolderPermsDialog] = useState(false);
  const [showFolderPermsNotification, setShowFolderPermsNotification] =
    useState(false);
  const [showAddedBundleNotification, setShowAddedBundleNotification] =
    useState(false);
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);
  const [showFolderCreationNotification, setShowFolderCreationNotification] =
    useState(false);
  const [openAddToInterviewDialog, setOpenAddToInterviewDialog] =
    useState(false);
  const [showAddedNotification, setShowAddedNotification] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [operationMessage, setOperationMessage] = useState<string>('');
  const [showOperationMessage, setShowOperationMessage] = useState(false);

  const refreshData = async () => {
    setOpenDrawer(false);
    setSelectedDocs(null);
    await handleGetMatterDetailDocuments(page, pageSize);
    await handleGetMatterDetailFolders(1);
  };

  const handleDetailDocs = async (doc: any) => {
    try {
      if (doc.folder) {
        folderDocsRequest(doc.id.toString(), '').then((response: any) => {
          navigate(`/matters/detail/${matterId}/folder/${doc.id.toString()}`, {
            state: {
              response,
              matterDetails,
              folderName: doc.name,
            },
          });
        });
      }
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const handleDownloadFile = async (targetResource: any | boolean = false) => {
    if (!targetResource && !selectedDocs) return;
    try {
      const data = await getDocumentURLRequest(
        matterId,
        targetResource ? targetResource.id : selectedDocs.id
      );
      const link = document.createElement('a');
      link.href = data.file;
      link.download = targetResource ? targetResource.name : selectedDocs.name;
      link.click();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const handleBatchDeleteDocs = async (targetId = false) => {
    try {
      await batchDeleteMatterDocsRequest(
        matterId,
        targetId ? [targetId] : checkedDocs
      );
      await refreshData();
      await handleUpdateMatterDetails();
      setShowArchiveNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  // Fraud
  const addToFraudDetectionHandler = async () => {
    await addToFraudDetectionDBRequest(matterId, checkedDocs);
    setOpenDrawer(false);
    setShowAddToFraudDatabase(true);
  };

  // rename folder / document
  const renameHandler = async () => {
    try {
      if (selectedDocs.folder) {
        await renameFolderRequest(selectedDocs.id, inputRenameValue);
      } else {
        await editDocumentRequest(
          matterId,
          selectedDocs.id,
          inputRenameValue,
          undefined,
          undefined
        );
      }
      setRenameDialogOpen(false);
      setInputRenameValue('');
      await refreshData();
      setShowRenameNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handleNewDocClick = () => {
    navigate(`/matter/addDocument/${matterId}/none`, {
      state: { currentStep: 3, matterDetails },
    });
  };

  const handleNewFolder = async () => {
    try {
      await createFolderRequest(inputRenameValue, matterId);
      setOpenCreateFolderDialog(false);
      setInputRenameValue('');
      await refreshData();
      setShowFolderCreationNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
      console.error(error);
    }
  };

  const folderUploadLinkHandler = async () => {
    if (!selectedDocs) return;

    try {
      const splitId = selectedDocs.id.split('-');

      const token = await createOrGetUploadTokenRequest(
        splitId[splitId.length - 1]
      );

      const uploadLink = `http://${window.location.host}:/matter/upload-portal/${token}`;
      navigator.clipboard.writeText(uploadLink);
      setShowCopyNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const fileDownloadLinkHandler = async (
    targetId: string | boolean = false
  ) => {
    if (!targetId && !selectedDocs) return;

    try {
      const token = await createOrGetDownloadTokenRequest(
        targetId || selectedDocs.id
      );

      const uploadLink = `http://${window.location.host}:/direct-link/${token}`;
      navigator.clipboard.writeText(uploadLink);
      setShowCopyNotification(true);
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowNotification(true);
    }
  };

  const informationSideBarHandler = (targetId = false) => {
    if (targetId) setSelectedDocs(targetId);
    setTimeout(() => {
      setOpenDrawer(true);
    }, 50);
  };

  const addNoteHandler = (targetId = false) => {
    if (targetId) setCheckedDocs([targetId]);
    setTimeout(() => {
      setOpenNoteModal(true);
    }, 50);
  };

  const renameHandlerOpen = (targetId = false) => {
    if (targetId) setSelectedDocs(targetId);

    setTimeout(() => {
      setRenameDialogOpen(true);
    }, 50);
  };

  const handleImanageDocumentsOperations = async (
    action: string,
    all_documents: boolean,
    targetResource: any | boolean = false,
    docs_to_upload?: any[],
    folder?: string
  ) => {
    try {
      if (action === 'pull') {
        await matterDetailImanageDocOpsNewRequest(
          matterId,
          action,
          // eslint-disable-next-line no-nested-ternary
          all_documents
            ? matterDetailDocs.documents.map((doc: any) => doc.id)
            : targetResource
            ? [targetResource]
            : checkedDocs,
          folder
        );
        setOperationMessage(
          'Importing iManage documents, this may take a while.'
        );
      } else if (action === 'push') {
        if (!docs_to_upload) {
          return;
        }
        const files: number[] = [];
        docs_to_upload.forEach((element: any) => {
          if (element.folder) {
            if (element.documents && Array.isArray(element.documents)) {
              element.documents
                .filter((resource: any) => !resource.folder)
                .forEach((doc: any) => {
                  files.push(doc.id);
                });
            }
            if (element.subfolders && Array.isArray(element.subfolders)) {
              element.subfolders.forEach((subfolder: any) => {
                if (subfolder.docs && Array.isArray(subfolder.docs)) {
                  subfolder.docs.forEach((doc: any) => {
                    files.push(doc.id);
                  });
                }
              });
            }
          } else {
            files.push(element.id);
          }
        });

        // await matterDetailImanageDocOpsRequest(
        //   {
        //     token: imanageUserData.token,
        //     customer: imanageUserData.customer,
        //   },
        //   matterId,
        //   action,
        //   files
        // );
        setOperationMessage(
          'Uploading documents, your files should arrive at imanage in a few minutes.'
        );
      }
      setShowOperationMessage(true);
      refreshData();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handleEdiscoveryDocumentsOperations = async (
    action: string,
    targetResource: any
  ) => {
    try {
      await matterDetailRelativityDocOpsRequest(matterId, action, [
        targetResource,
      ]);
      setOperationMessage(
        'Importing E-Discovery documents, this may take a while.'
      );
      setShowOperationMessage(true);
      refreshData();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  // make this general integrations after
  const handleMicrosoftDocumentsOperations = async (
    action: string,
    targetResource: any
  ) => {
    try {
      if (action === 'pull') {
        await matterDetailIntegrationOperationsRequest(
          matterId,
          'microsoft',
          action,
          targetResource
        );
        setOperationMessage(
          'Importing Microsoft document, this may take a while.'
        );
      }
      setShowOperationMessage(true);
      refreshData();
    } catch (error: any) {
      setRequestError(error.response.data.message);
      setShowErrorNotification(true);
    }
  };

  const handlePageChange = async (newPage: any) => {
    setOpenDrawer(false);
    setSelectedDocs(null);
    setPage(newPage);
    await handleGetMatterDetailDocuments(newPage, pageSize);
  };

  const handlePageSizeChange = async (newPageSize: any) => {
    setOpenDrawer(false);
    setSelectedDocs(null);
    setPageSize(newPageSize);
    await handleGetMatterDetailDocuments(page, newPageSize);
  };

  return (
    <>
      {showOperationMessage && (
        <CreationNotification
          title={operationMessage}
          subTitle=""
          showNotification={showOperationMessage}
          handleCloseNotification={() => setShowOperationMessage(false)}
        />
      )}
      {showErrorNotification && (
        <NotificationToast
          showNotification={showErrorNotification}
          requestError={requestError}
          handleCloseNotification={() => setShowErrorNotification(false)}
        />
      )}
      {showCopyNotification && (
        <CreationNotification
          title="Link copied to clipboard!"
          subTitle=""
          showNotification={showCopyNotification}
          handleCloseNotification={() => setShowCopyNotification(false)}
        />
      )}
      {showRenameNotification && (
        <CreationNotification
          title="Resource renamed successfully!"
          subTitle=""
          showNotification={showRenameNotification}
          handleCloseNotification={() => setShowRenameNotification(false)}
        />
      )}
      {showAddedNotification && (
        <CreationNotification
          title="Added to folder successfully!"
          subTitle=""
          showNotification={showAddedNotification}
          handleCloseNotification={() => setShowAddedNotification(false)}
        />
      )}
      {showFolderCreationNotification && (
        <CreationNotification
          title="Created folder successfully!"
          subTitle=""
          showNotification={showFolderCreationNotification}
          handleCloseNotification={() =>
            setShowFolderCreationNotification(false)
          }
        />
      )}
      {showAddToFraudDatabase && (
        <CreationNotification
          title="Successfully added to the fraud detection database!"
          subTitle=""
          showNotification={showAddToFraudDatabase}
          handleCloseNotification={() => setShowAddToFraudDatabase(false)}
        />
      )}
      {showAddedBundleNotification && (
        <CreationNotification
          title="Added to interview bundle successfully!"
          subTitle=""
          showNotification={showAddedBundleNotification}
          handleCloseNotification={() => setShowAddedBundleNotification(false)}
        />
      )}
      {showArchiveNotification && (
        <CreationNotification
          title="Resource archived successfully!"
          subTitle=""
          showNotification={showArchiveNotification}
          handleCloseNotification={() => setShowArchiveNotification(false)}
        />
      )}
      {showFolderPermsNotification && (
        <CreationNotification
          title="Changed folder permissions successfully!"
          subTitle=""
          showNotification={showFolderPermsNotification}
          handleCloseNotification={() => setShowFolderPermsNotification(false)}
        />
      )}
      {openNoteModal && (
        <MatterNotesListModal
          matterId={matterId}
          open={openNoteModal}
          setOpen={setOpenNoteModal}
          resourceName="InvestigationDocument"
          resourceId={checkedDocs[0]}
        />
      )}
      <DocumentDialog
        open={openRenameDialog}
        onClose={() => setRenameDialogOpen(false)}
        title={selectedDocs?.folder ? 'Rename Folder' : 'Rename Document'}
        label={selectedDocs?.folder ? 'Folder name ' : 'Document name'}
        primaryBtnText="OK"
        onPrimaryButtonClick={renameHandler}
        setInputValue={setInputRenameValue}
      />
      <DocumentDialog
        open={openCreateFolderDialog}
        onClose={() => setOpenCreateFolderDialog(false)}
        title="New Folder"
        label="Folder name"
        primaryBtnText="Create"
        onPrimaryButtonClick={handleNewFolder}
        setInputValue={setInputRenameValue}
      />
      <DocumentAddToInterviewDialog
        open={openAddToInterviewDialog}
        onClose={() => setOpenAddToInterviewDialog(false)}
        docList={matterDetailDocs?.documents}
        bundlesOptions={bundlesOptions}
        refreshTable={refreshData}
        matterId={matterId}
        control={control}
        setValue={setValue}
        setShowAddedBundleNotification={setShowAddedBundleNotification}
        selectedDocs={
          matterDetailDocs?.documents
            ? matterDetailDocs.documents.filter(
                (doc: { id: any; source: string }) =>
                  checkedDocs.includes(doc.id) && doc.source === 'Leiaa'
              )
            : []
        }
      />
      <DocumentAddToFolderDialog
        open={openAddToFolderDialog}
        onClose={() => setOpenAddToFolderDialog(false)}
        matterId={matterId}
        refreshTable={refreshData}
        setShowAddedNotification={setShowAddedNotification}
        selectedDocs={
          matterDetailDocs?.documents
            ? matterDetailDocs.documents.filter(
                (doc: { id: any; source: string }) =>
                  checkedDocs.includes(doc.id) && doc.source === 'Leiaa'
              )
            : []
        }
      />
      {openFolderPermsDialog && (
        <DocumentFolderPerms
          open={openFolderPermsDialog}
          onClose={() => setOpenFolderPermsDialog(false)}
          refreshTable={refreshData}
          setShowNotification={setShowFolderPermsNotification}
          folder={selectedDocs}
          userList={matterDetails?.users}
        />
      )}
      {/* Top actions */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '40px',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '32px',
          }}
        >
          {documentPerms.add && (
            <>
              <Button
                variant="contained"
                onClick={handleNewDocClick}
                sx={{ ...primaryButtonStyles(), height: '40px' }}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add documents</Typography>
              </Button>
              <Button
                variant="contained"
                onClick={() => setOpenCreateFolderDialog(true)}
                sx={{ ...secondaryButtonStyles(), height: '40px' }}
              >
                <span className="material-icons-round">add</span>
                <Typography>Add Folder</Typography>
              </Button>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            marginLeft: 'auto',
          }}
        >
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search by document..."
            sx={homePortalSearchInputStyles}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{ '& > span': { color: '#69707B' } }}
                >
                  <span className="material-icons-round">search</span>
                </InputAdornment>
              ),
              disableUnderline: true,
              onChange: handleListSearchbarInputChange,
              inputRef,
            }}
            variant="standard"
          />
        </Box>
      </Box>
      {/* Sources */}
      <MatterDetailDocumentsSources
        matterId={matterId}
        toggledIntegrations={toggledIntegrations}
        setToggledIntegrations={setToggledIntegrations}
      />
      {/* Folders */}
      <MatterDetailDocumentsFolders
        handleDetailDocs={handleDetailDocs}
        matterDetailFolders={matterDetailFolders}
        loadingFolders={loadingFolders}
      />
      {/* All files */}

      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              color: '#69707B',
              ...karlaProBoldFontStyles,
              fontSize: '16px',
              lineHeight: '22px',
              marginBottom: '5px',
            }}
          >
            All files
          </Typography>
        </Box>
        <Box>
          {selectedDocs && (
            <FolderFileInformationDrawer
              item={selectedDocs}
              open={openDrawer}
              setOpen={setOpenDrawer}
              folderPermissionsHandler={() => setOpenFolderPermsDialog(true)}
              folderUploadLinkHandler={folderUploadLinkHandler}
              handleDownloadFile={handleDownloadFile}
              addToFraudDetectionHandler={addToFraudDetectionHandler}
              renameHandler={() => setRenameDialogOpen(true)}
              fileDownloadLinkHandler={fileDownloadLinkHandler}
            />
          )}
        </Box>
      </Stack>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          '& > div:nth-of-type(1)': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flex: '1 0 0',
          },
          marginTop: '20px',
        }}
      >
        {documentPerms.add && (
          <ListButtons
            checkedResources={checkedDocs}
            resourceName="Documents"
            addToInterviewHandler={() => setOpenAddToInterviewDialog(true)}
            addToFolderHandler={() => setOpenAddToFolderDialog(true)}
            addToFraudDetectionHandler={addToFraudDetectionHandler}
            deleteHandler={handleBatchDeleteDocs}
          />
        )}
      </Box>

      <DocumentsListTable
        documentsListData={matterDetailDocs}
        listClass="docs-list"
        loading={loadingDocs}
        setCheckedDocs={setCheckedDocs}
        setSelectedDocs={setSelectedDocs}
        handleDetailDoc={handleDetailDocs}
        informationSideBarHandler={informationSideBarHandler}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        pageSize={pageSize}
        // action handlers
        addNoteHandler={addNoteHandler}
        handleDownloadFile={handleDownloadFile}
        renameHandler={renameHandlerOpen}
        fileDownloadLinkHandler={fileDownloadLinkHandler}
        deleteHandler={handleBatchDeleteDocs}
        handleImanageDocumentsOperations={handleImanageDocumentsOperations}
        handleMicrosoftDocumentsOperations={handleMicrosoftDocumentsOperations}
        handleEdiscoveryDocumentsOperations={
          handleEdiscoveryDocumentsOperations
        }
      />
    </>
  );
};

export default MatterDetailDocumentTab;
