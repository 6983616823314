import { Box, Stack, Typography, Skeleton } from '@mui/material';
import {
  karlaProBoldFontStyles,
  karlaProRegularFontStyles,
} from '../../../../styles/textStyles';

interface MatterDetailDocumentsFoldersProps {
  handleDetailDocs: any;
  matterDetailFolders: any;
  loadingFolders: boolean;
}

const MatterDetailDocumentsFolders = ({
  handleDetailDocs,
  matterDetailFolders,
  loadingFolders,
}: MatterDetailDocumentsFoldersProps) => {
  return (
    <Box sx={{ padding: '10px 0px' }}>
      <Typography
        sx={{
          color: '#69707B',
          ...karlaProBoldFontStyles,
          fontSize: '16px',
          lineHeight: '22px',
          marginBottom: '15px',
        }}
      >
        Folders
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          overflowX: 'scroll',
        }}
        className="scrollable-content"
      >
        {loadingFolders
          ? Array.from({ length: 7 }).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={200}
                height={34}
                sx={{
                  borderRadius: '15px',
                  backgroundColor: '#F5FAFD',
                }}
              />
            ))
          : matterDetailFolders.folders.map((folder: any) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '16px',
                  border: '1px solid #F5FAFD',
                  height: '34px !important',
                  maxHeight: '34px !important',
                  minHeight: '34px !important',
                  backgroundColor: '#F5FAFD',
                  borderRadius: '15px',
                  cursor: 'pointer',
                  '&:hover': {
                    border: '1px solid #bcd2ff',
                  },
                }}
                key={folder.id}
                onClick={() => handleDetailDocs(folder)}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: '#69707B',
                    ...karlaProRegularFontStyles,
                    fontSize: '14px',
                    lineHeight: '22px',
                    '& > span': {
                      fontSize: '20px',
                    },
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span className="material-icons-round">folder</span>
                  {folder.name}
                </Typography>
              </Box>
            ))}
      </Stack>
    </Box>
  );
};

export default MatterDetailDocumentsFolders;
